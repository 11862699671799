<template>
	<div class="jobhunting">
		<!-- 搜索栏 -->
		<div class="job-search">
			<div class="search-content">
				<div class="search">
					<el-form ref="form">
						<el-form-item>
							<div class="search-input">
								<el-input v-model="searchKey" placeholder="请输入关键字"></el-input>
								<!-- <el-select v-model="form.field1" placeholder="职位类型">
                  <el-option label="全部" value="shanghai"></el-option>
                  <el-option label="职位类型2" value="beijing"></el-option>
                </el-select> -->
								<!-- <el-select
                  v-model="form.industry"
                  clearable
                  placeholder="公司行业"
                >
                  <el-option
                    v-for="item in educationlist"
                    :key="item.cCode"
                    :label="item.cName"
                    :value="item.cCode"
                  ></el-option>
                </el-select> -->
							</div>
							<div class="search-btn">
								<el-button type="primary" v-preventReClick @click="
                    pageNumber = 1;
                    GetList();
                  ">搜索</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
				<div class="search-hottel">
					<img src="../../assets/img/hottel.png" alt="" />客服热线：<span>0579-89302257</span>
				</div>
			</div>
		</div>
		<!-- 推荐岗位 -->
		<div class="job-position">
			<div class="position-content">
				<div class="position-title">推荐企业</div>
				<div class="companyList" v-for="(coItem, company) in companydata" :key="company"
					@click="goto({ id: coItem.coM_ID })">
					<div class="companyLeft">
						<div class="logo">
							<el-image :src="setcompanylogo(coItem.coM_LOGO)" fit="contain" alt="" />
						</div>
						<div class="companyInfo">
							<div class="companyName">
								<div>{{ coItem.coM_NAME }}</div>
								<div class="address">
									{{ coItem.coM_ADDRESS ? coItem.coM_ADDRESS : "金华-东阳" }}
								</div>
							</div>
							<div class="factor">
								<div class="factorItem" v-if="coItem.coM_INDUSTRY">
									{{ coItem.coM_INDUSTRY }}
								</div>
								<div class="factorItem" v-if="coItem.coM_NATURE">
									{{ coItem.coM_NATURE }}
								</div>
								<div class="factorItem" v-if="coItem.coM_SCALE">
									{{ coItem.coM_SCALE }}
								</div>
							</div>
							<div class="post"><b>在招岗位：</b>{{ coItem.info }}</div>
						</div>
					</div>
					<div class="companyRight">
						<span>{{ utils.handleDateHYD(coItem.coM_UPDATE_DATE) }}</span>
						<div class="companyRight-btn">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="200" v-if="companydata.length < 1"></el-empty>
			</div>

			<div style="text-align: center; margin-bottom: 20px">
				<el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="pageNumber" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Gethotcompanylist
	} from "@/api/home";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		data() {
			return {
				companydata: [],
				defimgurl: global.baseimgurl,
				searchKey: "",
				total: 0,
				cur: 0,

				curr: 0,
				pageNumber: 1,
				pageSize: 10,

				user: {},
			};
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true);
		},
		created() {
			this.GetList();

			if (!isnull(localStorage.getItem("userinfo"))) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				return false;
			}
		},
		methods: {
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			enter(i) {
				this.curr = i;
			},
			leave() {
				this.curr = null;
			},
			goPage(path) {
				this.$router.push(path);
			},
			GetList() {
				var _this = this;
				var par = {
					pageNumber: _this.pageNumber,
					pageSize: _this.pageSize,
					searchKey: _this.searchKey,
				};
				Gethotcompanylist(par).then((res) => {
					if (res.success) {
						_this.companydata = res.data.rows;
						// console.log("Gethotcompanylists", res.data.rows);
						_this.total = res.data.total;
						_this.backtop();
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			handleSizeChange(val) {
				this.pageNumber = 1;
				this.pageSize = val;
				this.GetList();
			},
			handleCurrentChange(val) {
				this.pageNumber = val;
				this.GetList();
			},

			backtop() {
				var timer = setInterval(function() {
					let osTop =
						document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 30);
			},

			goto(data) {
				this.resolvewin("/company", data, 2);
			},

			setcompanylogo(url) {
				return isnull(url) ?
					require("@/assets/img/deflogo.png") :
					this.defimgurl + url;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.job-search {
		position: relative;
		width: 100%;
		background: #fff;
		border-top: 1px solid #eee;
		margin-bottom: 16px;

		.search-content {
			width: 1200px;
			margin: 0px auto;
			padding: 15px 0px;
			display: flex;
			justify-content: space-between;

			.search {
				flex: 1;
				width: 100%;

				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.el-form-item__content {
							display: flex;
							align-items: stretch;

							.search-input {
								display: flex;
								border: 1px solid #eee;

								.el-input {
									.el-input__inner {
										border: none;
										width: 700px;
									}
								}

								.el-select {
									.el-input__inner {
										border: none;
										width: 300px;
									}

									.el-input::before {
										content: "";
										position: absolute;
										width: 1px;
										height: 18px;
										left: 0px;
										top: 11px;
										background: #ddd;
									}
								}
							}

							.search-btn {
								.el-button {
									background: #66b1ff;
									border-radius: 0;
									height: 100%;
									padding: 12px 30px;
									width: 150px;
								}
							}
						}
					}
				}
			}

			.search-hottel {
				display: flex;
				align-items: center;
				font-size: 14px;

				img {
					width: 22px;
					height: 22px;
					margin-right: 4px;
				}

				span {
					font-size: 18px;
					color: #66b1ff;
				}
			}
		}

		.select-content {
			width: 1200px;
			margin: 0px auto;
			padding-bottom: 15px;
			display: flex;
			justify-content: space-between;

			.select-cell {
				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.select-input {
							.el-select {
								margin-right: 60px;

								.el-input__inner {
									width: 110px;
									padding-left: 0;
									border: none;
								}
							}
						}
					}
				}
			}

			.select-clear {
				font-size: 14px;
				color: #787878;
				line-height: 40px;
				cursor: pointer;
			}
		}
	}

	.job-position {
		position: relative;
		width: 1200px;
		margin: 0px auto;

		.position-content {
			background: white;
			padding: 25px 25px 0 25px;
			box-sizing: border-box;
			margin-bottom: 50px;

			.position-title {
				text-align: center;
				font-size: 16px;
				color: #333;
				font-weight: bold;
			}

			.companyList {
				display: flex;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				padding: 20px 0;
				border-bottom: 1px solid #eee;

				.companyLeft {
					display: flex;
					align-items: center;
					width: 85%;

					.logo {
						width: 109px;
						height: 109px;
						border: 1px solid #e1e1e1;
						border-radius: 4px;
						overflow: hidden;
						margin-right: 15px;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 100%;
							height: 100%;
						}
					}

					.companyName {
						font-size: 16px;
						color: #333;
						font-weight: bold;
						display: flex;
						align-items: flex-end;
						margin-bottom: 10px;

						.address {
							font-size: 14px;
							color: #ff5722;
							margin-left: 10px;
						}
					}

					.factor {
						display: flex;
						font-size: 14px;
						color: #666;
						margin-bottom: 10px;

						.factorItem {
							padding: 0 15px;
							border-right: 1px solid #eee;
						}

						.factorItem:first-child {
							padding-left: 0;
						}

						.factorItem:last-child {
							border-right: none;
						}
					}

					.post {
						font-size: 14px;
						color: #999;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.companyRight {
					width: 150px;
					height: 80px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					font-size: 14px;

					.companyRight-btn {
						width: 150px;
						height: 35px;
						line-height: 35px;
						background-color: #276bf2;
						color: white;
						font-size: 14px;
						text-align: center;
						border-radius: 4px;
						cursor: pointer;
					}
				}
			}

			.companyList:last-child {
				border-bottom: none;
			}
		}
	}

	.companyInfo {
		width: 80%;
	}
</style>
